import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dropbox } from "dropbox";

const accessToken =
  "mHol3eAjko0AAAAAAAAAAes2Np5SEbWPMBH745LQLcfDJVovSiCsXe-nbfsCW6ej";
const dropbox = new Dropbox({ accessToken });

const ControlPage = () => {
  // State for blob
  const [video, setVideo] = useState(undefined);

  //
  useEffect(() => {
    getItem();
  }, []);

  const getItem = () => {
    // Gets Array of files
    dropbox
      .filesListFolder({ path: "" })
      .then(function (response) {
        // Gets latest entry
        const resArr = response.result.entries;
        const latestEntry = resArr[resArr.length - 1];
        // Fetches Blob
        dropbox
          .filesDownload({
            path: latestEntry.path_lower,
          })
          .then((res) => {
            // sets state to blob
            setVideo(res.result.fileBlob);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const renderVideo = () => {
    // Only renders when blob is fetched
    if (video) {
      // premade JS function that does the blob data work
      const itemURL = URL.createObjectURL(video);
      // returns
      // return <img src={itemURL} alt="" />;
      return <video src={itemURL} autoPlay="autoplay" loop="loop"></video>;
    }
    return null;
  };

  return <Container>{renderVideo()}</Container>;
};

const Container = styled.div`
  height: 100%;
  width: 100%;

  video {
    object-fit: scale-down;
    width: 100%;
  }
`;

export default ControlPage;
