import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getTime, toDate } from "date-fns";
import styled from "styled-components";
import "../App.css";

const theme = createTheme({
  palette: {
    primary: { main: "#76a300" },
  },
});

const ConfigureTickets = ({ view, setView, setTicket, buffer }) => {
  const [length, setLength] = useState("30d");
  const [area, setArea] = useState("Nord-Jæren");

  const handleLengthChange = (e) => {
    setLength(e.target.value);
  };

  const handleAreaChange = (e) => {
    setArea(e.target.value);
  };

  const getLengthInMs = (length) => {
    switch (length) {
      case "24h":
        return 86400000;
        break;
      case "7d":
        return 604800000;
        break;
      case "30d":
        return 2592000000;
        break;
      default:
        break;
    }
  };

  const onSubmit = () => {
    const date = Date.now();
    const expiry = date + getLengthInMs(length) - buffer; // buffer
    // alert(`${length}, ${toDate(expiry)}`);
    setTicket({ expiry, area });
    setView("ticket");
  };

  return (
    <ThemeProvider theme={theme}>
      <TitleBar className={view === "configure" ? "" : "hidden"}>
        Billetter
      </TitleBar>
      <Content className={view === "configure" ? "" : "hidden"}>
        <div className="card">
          <h2 className="title">{`"Kjøp" ny billett`}</h2>
          <div className="content">
            <FormControl className="form" component="fieldset">
              <FormLabel className="group-title" component="legend">
                Billett Lengde
              </FormLabel>
              <RadioGroup
                row
                className="radio-group"
                aria-label="length"
                value={length}
                onChange={handleLengthChange}
                name="radio-buttons-group"
              >
                <FormControlLabel value="24h" control={<Radio />} label="24t" />
                <FormControlLabel value="7d" control={<Radio />} label="7d" />
                <FormControlLabel value="30d" control={<Radio />} label="30d" />
              </RadioGroup>
            </FormControl>
            <FormControl className="form" component="fieldset">
              <FormLabel className="group-title" component="legend">
                Billett Område
              </FormLabel>
              <RadioGroup
                className="radio-group"
                aria-label="length"
                value={area}
                onChange={handleAreaChange}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Nord-Jæren"
                  control={<Radio />}
                  label="Nord-Jæren"
                />
                <FormControlLabel
                  value="Alle Soner"
                  control={<Radio />}
                  label="Alle Soner"
                />
              </RadioGroup>
            </FormControl>

            <div className="button-container">
              <Button onClick={onSubmit} className="button" variant="contained">
                Kjøp
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </ThemeProvider>
  );
};

const TitleBar = styled.div`
  text-align: left;
  position: fixed;
  z-index: 15;
  top: 0;
  padding: 20px;
  width: 100%;
  font-weight: 45;
  background-color: #4d4d4d;
  color: #fff;

  &.hidden {
    display: none;
  }
`;

const Content = styled.div`
  height: 85vh;
  margin-top: 60px;
  max-width: 100%;
  /* min-height: calc(100vh - 120px); */
  padding: 10px 7px;
  background-color: #24272c;
  background-color: #f5f5f5;

  &.hidden {
    display: none;
  }

  .card {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 1px 3px 1px rgba(152, 152, 152, 0.54);
    -webkit-box-shadow: 0px 1px 3px 1px rgba(152, 152, 152, 0.54);
    -moz-box-shadow: 0px 1px 3px 1px rgba(152, 152, 152, 0.54);
  }

  .title {
    padding: 10px;
    margin: 0;
    margin-bottom: 50px;
    font-weight: 45;
    background-color: rgba(152, 152, 152, 0.54);
  }

  .content {
    font-weight: 35;
    display: flex;
    height: 80%;
    flex-direction: column;
    justify-content: space-around;

    .form {
      margin: 15px;
    }

    .radio-group {
      margin: 0 auto;
    }

    .group-title {
      font-size: 120%;
      margin: 0 auto;
    }

    .button-container {
      /* margin: auto 0 50px 0; */
      .button {
        width: 40%;
        margin: 0 auto;
      }
    }
  }
`;

export default ConfigureTickets;
