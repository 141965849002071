import { createWorker } from "tesseract.js";
import { Dropbox } from "dropbox";

const accessToken =
  "mHol3eAjko0AAAAAAAAAAes2Np5SEbWPMBH745LQLcfDJVovSiCsXe-nbfsCW6ej";
const dropbox = new Dropbox({ accessToken });

const worker = createWorker({
  logger: (m) => console.log(m),
});

const getControlPicture = async () => {
  // Gets Array of files

  return new Promise((resolve) => {
    dropbox
      .filesListFolder({ path: "/control-text" })
      .then(function (response) {
        // Gets latest entry
        const resArr = response.result.entries;
        const latestEntry = resArr[resArr.length - 1];
        // Fetches Blob
        dropbox
          .filesDownload({
            path: latestEntry.path_lower,
          })
          .then((res) => {
            // resolve (Promise) blob as it is a valid object for tesseract
            resolve(res.result.fileBlob);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  });
};

export const getText = async (setControlText, setLoading) => {
  const blob = await getControlPicture();
  console.log(blob);

  await worker.load();
  await worker.loadLanguage("eng");
  await worker.initialize("eng");
  const {
    data: { text },
  } = await worker.recognize(blob);
  setControlText(text.toUpperCase());
  setLoading(false);
  await worker.terminate();
};
