import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

const Counter = ({ props }) => {
  const mainDays = useRef(null);
  const prevDays = useRef(null);
  const mainHours = useRef(null);
  const prevHours = useRef(null);
  const mainMins = useRef(null);
  const prevMins = useRef(null);
  const mainSecs = useRef(null);
  const prevSecs = useRef(null);

  const [prevTime, setPrevTime] = useState({
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  });
  const { days, hours, minutes, seconds } = props;

  const aniIn = (mainRef, prevRef) => {
    mainRef.current.classList.toggle("animate-in");
    prevRef.current.classList.toggle("animate-out");
  };

  const aniOut = (mainRef, prevRef) => {
    mainRef.current.classList.toggle("animate-in");
    prevRef.current.classList.toggle("animate-out");
  };

  const changeChecker = (time, prevTime) => {
    if (time != prevTime) {
      return true;
    }
  };

  useEffect(() => {
    let a, b, c, d;
    if (changeChecker(seconds, prevTime.seconds)) {
      aniIn(mainSecs, prevSecs);
      a = setTimeout(() => {
        aniOut(mainSecs, prevSecs);
      }, 700);
    }
    if (changeChecker(minutes, prevTime.minutes)) {
      aniIn(mainMins, prevMins);
      b = setTimeout(() => {
        aniOut(mainMins, prevMins);
      }, 700);
    }
    if (changeChecker(hours, prevTime.hours)) {
      aniIn(mainHours, prevHours);
      c = setTimeout(() => {
        aniOut(mainHours, prevHours);
      }, 700);
    }
    if (changeChecker(days, prevTime.days)) {
      aniIn(mainDays, prevDays);
      d = setTimeout(() => {
        aniOut(mainDays, prevDays);
      }, 700);
    }
    setPrevTime({ days, hours, minutes, seconds });

    return () => {
      clearTimeout(a);
      clearTimeout(b);
      clearTimeout(c);
      clearTimeout(d);
    };
  }, [days, hours, minutes, seconds]);

  return (
    <CounterStyled>
      <div className="title">UTLØPER OM</div>
      <div className="container">
        {/* DAYS */}
        <div className="box">
          <p ref={mainDays} className={`digit`}>
            {prevTime.days}
          </p>
          <p ref={prevDays} className={`prev digit`}>
            {prevTime.days + 1}
          </p>
          <p className="digit-title">dager</p>
        </div>
        {/* HOURS */}
        <div className="box">
          <p ref={mainHours} className={`digit`}>
            {prevTime.hours}
          </p>
          <p ref={prevHours} className={`prev digit`}>
            {prevTime.hours + 1}
          </p>
          <p className="digit-title">timer</p>
        </div>
        {/* MINUTES */}
        <div className="box">
          <p ref={mainMins} className={`digit`}>
            {prevTime.minutes}
          </p>
          <p ref={prevMins} className={`prev digit`}>
            {prevTime.minutes + 1}
          </p>
          <p className="digit-title">min</p>
        </div>
        {/* SECONDS */}
        <div className="box">
          <p ref={mainSecs} className={`digit`}>
            {prevTime.seconds}
          </p>
          <p ref={prevSecs} className={`prev digit`}>
            {prevTime.seconds + 1}
          </p>
          <p className="digit-title">sek</p>
        </div>
      </div>
    </CounterStyled>
  );
};

const CounterStyled = styled.div`
  .title {
    position: relative;
    z-index: 10;
    background-color: white;
    color: #6a6a6a;
    font-size: 85%;
    font-weight: 35;
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .container {
    display: flex;
    margin: 0 auto;
    width: 70%;
    justify-content: space-between;
  }

  .box {
    position: relative;
    display: inline-flex;
    font-weight: 45;
    font-size: 150%;
    color: black;
    width: 45px;
    height: 45px;
    background-color: #e8e8e8;
    border-radius: 1px;

    p {
      position: relative;
      z-index: 4;
    }

    .digit {
      padding-top: 7px;
      position: absolute;
      margin: 0;
      width: 100%;
    }

    .prev {
      opacity: 0;
    }

    .animate-in {
      animation: in 0.5s ease-in-out;
      @keyframes in {
        0% {
          top: -30px;
          opacity: 0;
        }
        100% {
          top: 0px;
          opacity: 1;
        }
      }
    }

    .animate-out {
      animation: out 0.5s ease-in-out;
      @keyframes out {
        0% {
          top: 0px;
          opacity: 0.4;
        }

        100% {
          top: 30px;
          opacity: 0;
        }
      }
    }

    .digit-title {
      /* fiddling with the z-index digit thingy */
      top: 32px;
      margin: 13px auto;
      /*  */
      font-size: 60%;
      width: 100%;
      position: absolute;
      z-index: 10;
      background-color: white;
      color: #6a6a6a;
      font-weight: 35;
    }
  }
`;

export default Counter;
