import React, { useState } from "react";
import Ticket from "./components/Ticket";
import DropBoxTest from "./components/DropBoxTest";
import ConfigureTickets from "./components/ConfigureTickets";
import "./App.css";

const App = () => {
  const [ticket, setTicket] = useState(null);
  const [view, setView] = useState("configure");
  const buffer = 16310000; // sets the time to before present

  return (
    <div className="App">
      {ticket ? (
        <Ticket view={view} setView={setView} ticket={ticket} buffer={buffer} />
      ) : null}
      <ConfigureTickets
        view={view}
        setView={setView}
        setTicket={setTicket}
        buffer={buffer}
      />
    </div>
  );
};

export default App;
