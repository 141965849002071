import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { toDate, format, getDay } from "date-fns";
import Counter from "./Counter";
import { getText } from "./OcrComponent";
import Loading from "./Loading";
import ControlModal from "./ControlModal";
import { getItem } from "./test";
import arrow from "../arrow.png";

function Ticket({ view, setView, ticket, buffer }) {
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const date = new Date();
  const savedDate = useRef(date.getTime());
  const [controlText, setControlText] = useState("");
  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState(undefined);

  useEffect(() => {
    // OCR getting the Control text from a picture
    getItem(setVideo);
    getText(setControlText, setLoading);
  }, []);

  const scrollTo = (id) => {
    document.querySelector(id).scrollIntoView({
      behavior: "smooth",
    });
  };

  const detailsClicked = (infoOpen, id) => {
    setInfoOpen(!infoOpen);
    if (!infoOpen) {
      setTimeout(() => {
        scrollTo(id);
      }, 1);
    }
  };

  const goBack = () => {
    // setTicket(null);
    setView("configure");
  };

  const formatDayString = (day) => {
    switch (day) {
      case 0:
        return "søndag";
      case 1:
        return "mandag";
      case 2:
        return "tirsdag";
      case 3:
        return "onsdag";
      case 4:
        return "torsdag";
      case 5:
        return "fredag";
      case 6:
        return "lørdag";

      default:
        break;
    }
  };

  const formatDate = (date, includeDay = false) => {
    let dayString;
    if (includeDay) {
      dayString = formatDayString(getDay(date));
    }
    const dateString = format(toDate(date), "dd.MM.yyyy HH:mm:ss");
    return `${dayString ? dayString : ""} ${dateString}`;
  };
  return (
    <Container className={view === "ticket" ? "" : "hidden"}>
      {loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <TitleBar>
            <BackButton onClick={goBack}>
              <img
                className="arrow-icon"
                src={arrow}
                width="18"
                height="18"
              ></img>
            </BackButton>
            Periodebillett
          </TitleBar>
          <Content>
            <div className="card">
              <div className="card-status-bar">
                <h2>Gyldig</h2>
              </div>
              <div className="bar-container">
                <div className="clock">
                  {date.getHours() < 10 ? "0" : null}
                  {date.getHours()}:
                  {date.getMinutes().toString().length < 2
                    ? `0${date.getMinutes()}`
                    : date.getMinutes()}
                </div>{" "}
                <div className="control-text">{controlText}</div>
              </div>
              <CardContent>
                <div className="text">
                  <a href="#">
                    se hvilke transportmidler denne billetten dekker
                  </a>
                </div>
                {/* needed for unmounting */}
                {ticket ? (
                  <Countdown
                    // 2592000000
                    date={ticket.expiry}
                    renderer={(props) => <Counter props={props} />}
                  ></Countdown>
                ) : null}

                {/* DIVIDER DOTTED LINE  */}
                <div className="divider">
                  <img src="dotted_line.png" alt="" />
                </div>

                <Info>
                  <div className="title">REISENDE</div>
                  <div className="zone">1 voksen</div>
                  <div className="title">GYLDIG I</div>
                  <div className="zone">{ticket.area}</div>
                </Info>
              </CardContent>
            </div>

            <InfoButton onClick={() => detailsClicked(infoOpen, "#bottom")}>
              {infoOpen ? "SKJUL" : "VIS"} DETALJER
            </InfoButton>

            <ExpiryInfo className={infoOpen ? "open" : null}>
              <div>Periodebillett</div>
              {/* Logic goes here */}
              <div>Utløper {formatDate(ticket.expiry, true)}</div>
              <div>Kjøpt {formatDate(Date.now() - buffer)}</div>
              <div>Pris kr 600,- herav mva. kr64,52</div>
              <div>Betalt med Vipps</div>
              <div>Bilettnummber: 27 17 12 67 8</div>
            </ExpiryInfo>
          </Content>
          <Button
            onClick={() => {
              setOpen(true);
              scrollTo("#top");
              setInfoOpen(false);
            }}
          >
            <img className="icon" src="qr-icon.svg" height="30" alt="" />
            <div className="text">KONTROLL</div>
          </Button>
          {/* MODAL FOR WHEN SHOWING TICKET */}
          {/* SEEMS LIKE CONTROL IMAGE IS ON A 2 SECOND LOOP */}
          {/* THIS MEANS THE RECORDED VIDEO SHOULD BE 2 SECONDS */}
          <ControlModal
            ticketArea={ticket.area}
            controlText={controlText}
            setOpen={setOpen}
            open={open}
            video={video}
          />
          <div id="bottom"></div>
        </>
      )}
    </Container>
  );
}

// As for now, because of the aspect ratio
// I divide 2280 (s10e screenshot height) by the devtools height (760)
// Which turns out to be 3, therefore we devide the screenshot heights by 3 to get equivalent ratio

const Container = styled.div`
  &.hidden {
    display: none;
  }
`;
const StatusBar = styled.div`
  position: fixed;
  z-index: 15;
  height: 39px;
  width: 100%;
  background-color: #323941;
`;

const TitleBar = styled.div`
  text-align: left;
  position: fixed;
  display: flex;
  z-index: 15;
  top: 0;
  padding: 20px;
  width: 100%;
  font-size: 120%;
  font-weight: 45;
  background-color: #4d4d4d;
  color: #fff;
`;

const BackButton = styled.div`
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .arrow-icon {
    margin: auto;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  left: 45%;
  top: 50%;
  /* height: 100vh; */
`;

const Content = styled.div`
  height: 100%;
  margin-top: 60px;
  max-width: 100%;
  min-height: calc(100vh - 120px);
  padding: 10px 7px;
  background-color: #24272c;
  background-color: #f5f5f5;

  .card {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 1px 3px 1px rgba(152, 152, 152, 0.54);
    -webkit-box-shadow: 0px 1px 3px 1px rgba(152, 152, 152, 0.54);
    -moz-box-shadow: 0px 1px 3px 1px rgba(152, 152, 152, 0.54);
  }

  .card-status-bar {
    background-color: #76a300;
    color: #ffffff;
    padding: 1px;

    h2 {
      margin: 10px;
      font-weight: 55;
    }
  }

  .bar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 45;
    background-color: #f5f5f5;
  }

  .clock {
    text-align: left;
    padding: 8px 15px;
  }

  .control-text {
    padding: 8px 15px;
  }
`;

const CardContent = styled.div`
  padding: 10px;

  .text {
    font-weight: 45;
    text-align: left;
    width: 90%;
    line-height: 105%;
    font-size: 95%;
  }

  .divider {
    img {
      padding-top: 60px;
      width: 90%;
      height: 1.5px;
      opacity: 0.25;
      object-fit: cover;
    }
  }
`;

const Info = styled.div`
  font-weight: 45;
  padding-top: 50px;
  text-align: left;

  .title {
    color: #6a6a6a;
    font-weight: 35;
    font-size: 85%;
  }

  .zone {
    font-size: 140%;
    font-weight: 55;
    padding-bottom: 20px;
  }

  .text {
    line-height: 105%;
    font-size: 95%;
  }
`;

const Button = styled.div`
  position: fixed;
  margin-top: 15px;
  background-color: #3679f5;
  width: 150px;
  transform: translate(calc(50vw - 50%));
  left: 0;
  bottom: 20px;
  height: 45px;
  border-radius: 50px;
  display: inline-flex;

  .text {
    width: 100%;
    font-weight: 55;
    padding-right: 10px;
    font-size: 105%;
    padding-bottom: 2px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icon {
    padding-left: 15px;
    align-self: center;
  }
`;

const InfoButton = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
  color: #6a6a6a;
  font-weight: 55;
  font-size: 85%;
`;

const ExpiryInfo = styled.div`
  &.open {
    display: block;
  }
  display: none;
  font-weight: 45;
  font-size: 90%;
  line-height: 200%;
  color: #24272c;
  padding-bottom: 80px;
`;

const ControlVideo = styled.div`
  height: 100%;
`;

const ModalButton = styled.div`
  margin-top: 15px;
  background-color: #ccd3dd;
  height: 45px;
  border-radius: 2px;
  width: 100%;
  display: inline-flex;

  .text {
    width: 100%;
    font-weight: 45;
    font-size: 115%;
    padding-bottom: 2px;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export default Ticket;
