import { Dropbox } from "dropbox";

const accessToken =
  "mHol3eAjko0AAAAAAAAAAes2Np5SEbWPMBH745LQLcfDJVovSiCsXe-nbfsCW6ej";
const dropbox = new Dropbox({ accessToken });

export const getItem = (setVideo) => {
  // Gets Array of files
  dropbox
    .filesListFolder({ path: "" })
    .then(function (response) {
      // Gets latest entry
      const resArr = response.result.entries;
      const latestEntry = resArr[resArr.length - 1];
      // Fetches Blob
      dropbox
        .filesDownload({
          path: latestEntry.path_lower,
        })
        .then((res) => {
          // sets state to blob
          setVideo(res.result.fileBlob);
        });
    })
    .catch(function (error) {
      console.error(error);
    });
};
