import React from "react";
import styled from "styled-components";
import DropBoxTest from "./DropBoxTest";

const date = new Date();

const ControlModal = ({ ticketArea, controlText, setOpen, open, video }) => {
  const renderVideo = () => {
    // Only renders when blob is fetched
    if (video) {
      // premade JS function that does the blob data work
      const itemURL = URL.createObjectURL(video);
      // returns
      // return <img src={itemURL} alt="" />;
      return <img src={itemURL}></img>;
    }
    return null;
  };

  return (
    <Modal id="top" className={open ? "open" : null}>
      {!video ? null : (
        <>
          <div className="content">
            <div className="title">
              1 voksen, <br /> {ticketArea}
            </div>
            {/* 17:43, 94 */}
            <div className="clock">
              {date.getHours() < 10 ? "0" : null}
              {date.getHours()}:
              {date.getMinutes().toString().length < 2
                ? `0${date.getMinutes()}`
                : date.getMinutes()}{" "}
              {controlText}
            </div>
            <div className="control-video">{renderVideo()}</div>

            <div onClick={() => setOpen(false)} className="button">
              <div className="text">LUKK</div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

const Modal = styled.div`
  position: absolute;
  z-index: 15;
  visibility: hidden;
  &.open {
    visibility: visible;
  }
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: #07070785;
  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    font-weight: 45;
    border-radius: 8px;
    width: 80%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .control-video {
      height: 100%;
      width: 100%;

      img {
        object-fit: scale-down;
        width: 100%;
      }
    }

    .title {
      font-size: 250%;
      padding-top: 10px;
    }

    .clock {
      font-weight: 35;
      color: #6a6a6a;
    }

    .button {
      width: 90%;
      /* margin: auto auto; */
      margin: auto 30px auto auto;
      height: 35px;
      font-size: 80%;
      margin-bottom: 10px;
      text-align: right;
    }
    .text {
      font-weight: 65;
      margin-right: 10px;
      color: #0d3888;
    }
  }
`;

export default ControlModal;
